import React from "react"

import Aos from "aos"

import RootRoutes from "./RootRoutes"

const App = () => {
  Aos.init()
  return <RootRoutes />
}

export default App
