import React from "react"

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import { ScrollToTop } from "./components"
import { useAnalytics } from "./hooks"
// import Layout from "./layouts/Layout"
// import Error404 from "./pages/errors/404"

// const Login = React.lazy(() => import("./pages/auth/Login"))
// const Register = React.lazy(() => import("./pages/auth/Register"))
// const Home = React.lazy(() => import("./pages/home/Home"))
// const About = React.lazy(() => import("./pages/about/About"))
// const Insights = React.lazy(() => import("./pages/insights/Insights"))
// const VMS = React.lazy(() => import("./pages/vms/VMS"))
// const Signage = React.lazy(() => import("./pages/signage/Signage"))
// const ContactUS = React.lazy(() => import("./pages/contact/ContactUS"))

const ExternalRedirect = ({
  url = "https://www.madelevator.com/solutions/mosaic-one"
}) => {
  // Redirect to the external URL
  window.location.href = url
  return null
}

const AllRoutes = () => {
  useAnalytics()
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="login"
          // element={
          //   <LazyElement>
          //     <Login />
          //   </LazyElement>
          // }
          element={<ExternalRedirect url="https://mosaicone.net/login" />}
        />

        <Route
          path="register"
          // element={
          //   <LazyElement>
          //     <Register />
          //   </LazyElement>
          // }
          element={<ExternalRedirect url="https://mosaicone.net/login" />}
        />

        <Route
          path="/"
          // element={
          //   <LazyElement>
          //     <Layout />
          //   </LazyElement>
          // }
        >
          <Route
            path=""
            // element={
            //   <LazyElement>
            //     <Home />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/solutions/mosaic-one" />
            }
          />

          <Route
            path="home"
            // element={
            //   <LazyElement>
            //     <Home />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/solutions/mosaic-one" />
            }
          />

          <Route
            path="about"
            // element={
            //   <LazyElement>
            //     <About />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/solutions/mosaic-one" />
            }
          />

          <Route
            path="vms"
            // element={
            //   <LazyElement>
            //     <VMS />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/solutions/video-and-messaging-system" />
            }
          />

          <Route
            path="signage"
            // element={
            //   <LazyElement>
            //     <Signage />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/products/digital-signage" />
            }
          />

          <Route
            path="insights"
            // element={
            //   <LazyElement>
            //     <Insights />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/products/performance-monitoring" />
            }
          />

          <Route
            path="contact"
            // element={
            //   <LazyElement>
            //     <ContactUS />
            //   </LazyElement>
            // }
            element={
              <ExternalRedirect url="https://www.madelevator.com/contact" />
            }
          />
        </Route>

        <Route path="/madmosaicone">
          <Route
            path=""
            element={
              <ExternalRedirect url="https://www.madelevator.com/solutions/mosaic-one" />
            }
          />

          <Route
            path="insights"
            element={
              <ExternalRedirect url="https://www.madelevator.com/products/performance-monitoring" />
            }
          />

          <Route
            path="about"
            element={
              <ExternalRedirect url="https://www.madelevator.com/solutions/mosaic-one" />
            }
          />

          <Route
            path="signage"
            element={
              <ExternalRedirect url="https://www.madelevator.com/products/digital-signage" />
            }
          />

          <Route
            path="vms"
            element={
              <ExternalRedirect url="https:/madelevator.com/solutions/video-and-messaging-system" />
            }
          />

          <Route
            path="contact"
            element={
              <ExternalRedirect url="https://www.madelevator.com/contact" />
            }
          />
        </Route>

        <Route
          path="404"
          // element={<Error404 />}
          element={
            <ExternalRedirect url="https://www.madelevator.com/solutions/mosaic-one" />
          }
        />

        <Route
          path="*"
          element={
            <Navigate
              to="/404"
              replace
            />
          }
        />
      </Routes>
    </>
  )
}

const RootRoutes = () => (
  <BrowserRouter>
    <AllRoutes />
  </BrowserRouter>
)

export default RootRoutes
