import React from "react"

import { Box, CircularProgress } from "@mui/material"
import { ErrorBoundary } from "react-error-boundary"

import ErrorBoundaryFallback from "./ErrorBoundaryFallback"

const LazyElement = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={ErrorBoundaryFallback}
    onReset={() => {}}
  >
    <React.Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "calc(100vh - 141px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      }
    >
      {children}
    </React.Suspense>
  </ErrorBoundary>
)

export default LazyElement
