import ga4 from "react-ga4"

import { APP_NAME, MEASUREMENT_ID } from "../config"

const TRACKING_ID = MEASUREMENT_ID
const isProduction = process.env.NODE_ENV === "production"

export const init = () => {
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
    debug: false
  })
}

export const sendEvent = (name) => {
  ga4.event("screen_view", {
    app_name: APP_NAME,
    screen_name: name
  })
}

export const sendPageview = (path) => {
  ga4.send({
    hitType: "pageview",
    page: path
  })
}
